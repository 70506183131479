import React from 'react';
import { bool } from 'prop-types';

import useStyles from 'apputil/page-styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserHardHat } from '@fortawesome/pro-duotone-svg-icons';

import { Grid, Typography } from '@material-ui/core';

const MenAtWorkView = ({ useTitle = true }) => {
  const styles = useStyles();

  return (
    <>
      <Grid spacing={2}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
      {useTitle && (
        <Grid item xs={12} sm={10}>
          <Typography variant="h3" component="h1" className={styles.workingHeader}>
            Comming soon
          </Typography>
        </Grid>
      )}
        <Grid item xs={12} sm={10}>
          <div className={styles.fourFourContainer}>
            <FontAwesomeIcon icon={faUserHardHat} className={styles.workingIcon}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography variant="h5" component="p" className={styles.workingMessage}>
           We are working hard to add this functionality
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

MenAtWorkView.propTypes = {
  useTitle: bool,
};

export default MenAtWorkView;
